import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {ConfigStore} from "./config.store";
import {ResponseState} from "../../shared/models/api/response";
import {AppConfig, Language} from "../../shared/models/store/AppConfig";
import {catchError, of, tap} from "rxjs";
import {arrayUpsert, setLoading} from "@datorama/akita";
import {SettingsSandbox} from "../../data-access/api-settings/SettingsSandbox";
import {SettingsEmail} from "../../data-access/api-settings/SettingsEmail";
import {Organization} from "../../data-access/api-settings/Organization";

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  public baseUrl: string;
  private apiV1: string = environment.apiV1;
  private baseRef: string = environment.origin;
  private serviceCore: string = environment.service.core;

  constructor(
    private httpClient: HttpClient,
    private store: ConfigStore
  ) {
    this.baseUrl = environment.local;
  }

  public setLoading(isLoading: boolean): void {
    this.store.setLoading(isLoading);
  }

  public initConfig(): void {
    this.resetLanguage();
    this.resetSettingsEmail();
    this.resetSettingsSandbox();
    this.resetOrganization();
  }

  public resetLanguage(): void {
    this.fetchLanguages()
      .pipe(
        setLoading(this.store),
        tap((languages: ResponseState<Language[]>) => {
          this.store.update((state: AppConfig) => ({
            ...state,
            languages: languages.payload
          }));
        }),
        catchError((error) => {
          this.store.setError(error);
          return of(error);
        })
      ).subscribe();
  }

  public resetSettingsEmail(): void {
    this.fetchSettingsEmail()
      .pipe(
        setLoading(this.store),
        tap((settingsEmails: SettingsEmail[]) => {
          this.store.update((state: AppConfig) => ({
            ...state,
            settingsEmail: settingsEmails
          }));
        }),
        catchError((error) => {
          this.store.setError(error);
          return of(error);
        })
      ).subscribe();
  }

  public resetSettingsSandbox(): void {
    this.fetchSettingsSandbox()
      .pipe(
        setLoading(this.store),
        tap((settingsSandboxes: SettingsSandbox[]) => {
          this.store.update((state: AppConfig) => ({
            ...state,
            settingsSandbox: settingsSandboxes
          }));
        }),
        catchError((error) => {
          this.store.setError(error);
          return of(error);
        })
      ).subscribe();
  }

  public resetOrganization(): void {
    this.fetchOrganization()
      .pipe(
        setLoading(this.store),
        tap((organizations: Organization[]) => {
          this.store.update((state: AppConfig) => ({
            ...state,
            organizations
          }));
        }),
        catchError((error) => {
          this.store.setError(error);
          return of(error);
        })
      ).subscribe();
  }

  /**
   *
   * UPDATE DATA
   */

  public upsertSettingsEmail(settingsEmail: SettingsEmail): void {
    this.store.update((state: AppConfig) => {
      const settingsEmails: SettingsEmail[] = arrayUpsert(state.settingsEmail, settingsEmail.id, settingsEmail);
      return {
        ...state,
        settingsEmail: settingsEmails
      };
    });
  }

  public upsertSettingsSandbox(settingsSandbox: SettingsSandbox): void {
    this.store.update((state: AppConfig) => {
      const settingsSandboxes: SettingsSandbox[] = arrayUpsert(state.settingsSandbox, settingsSandbox.id, settingsSandbox);
      return {
        ...state,
        settingsSandbox: settingsSandboxes
      };
    });
  }

  public upsertOrganization(organization: Organization): void {
    this.store.update((state: AppConfig) => {
      const organizations: Organization[] = arrayUpsert(state.organizations, organization.id, organization);
      return {
        ...state,
        organizations
      };
    });
  }

  /**
   *
   * FETCH DATA
   */

  public fetchLanguages = () => this.httpClient.get<ResponseState<Language[]>>(
    `${this.baseUrl}/languages.json`
  );

  public fetchSettingsEmail = () => this.httpClient.get<SettingsEmail[]>(
    `${this.baseRef}${this.serviceCore}${this.apiV1}settings/email`
  );

  public fetchSettingsSandbox = () => this.httpClient.get<SettingsSandbox[]>(
    `${this.baseRef}${this.serviceCore}${this.apiV1}settings/sandbox`
  );

  public fetchOrganization = () => this.httpClient.get<Organization[]>(
    `${this.baseRef}${this.serviceCore}${this.apiV1}settings/organization`
  );
}
