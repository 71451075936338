import {Injectable} from "@angular/core";

@Injectable({ providedIn: 'root' })
export class CookieService {

  constructor() { }

  public getCookie(name: string): string {
    const value: string = `; ${document.cookie}`;
    const parts: string[] = value.split(`; ${name}=`);
    if (parts.length === 2) {
      return parts.pop()?.split(';').shift() || '';
    }
    return '';
  }

}
