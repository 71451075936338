import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";
import {CookieService} from "../services/local/cookie.service";
import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";

@Injectable()
export class CsrfInterceptor implements HttpInterceptor {
  private local: string = environment.local;
  private origin: string = environment.origin;

  constructor(
    private cookieService: CookieService
  ) { }

  private static setHeader(req: HttpRequest<any>, csrfToken: string): HttpRequest<any> {
    return req.clone({ setHeaders: {
        'X-XSRF-TOKEN': csrfToken,
      },
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const csrfToken: string = this.cookieService.getCookie('XSRF-TOKEN');
    if (csrfToken
      && (req.url.startsWith(this.local)
      || req.url.startsWith(this.origin))
    ) {
      req = CsrfInterceptor.setHeader(req, csrfToken);
    }
    return next.handle(req);
  }

}
