import {LOCALE_ID, NgModule} from '@angular/core';
import {registerLocaleData} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {NG_ENTITY_SERVICE_CONFIG} from '@datorama/akita-ng-entity-service';
import {AkitaNgDevtools} from '@datorama/akita-ngdevtools';
import {environment} from '../environments/environment';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import fr from '@angular/common/locales/fr';
import {NZ_I18N, fr_FR} from 'ng-zorro-antd/i18n';
import {NzNotificationModule} from "ng-zorro-antd/notification";
import {IconModule} from "./shared/components/icon/icon.module";
import {FullCalendarModule} from "@fullcalendar/angular";
import {QuillModule} from "ngx-quill";
import {
  ModalEquipmentCategoryEditModule
} from "./shared/components/modals/modal-equipment-category-edit/modal-equipment-category-edit.module";
import {
  ModalEquipmentMaterialEditModule
} from "./shared/components/modals/modal-equipment-material-edit/modal-equipment-material-edit.module";
import {
  ModalEquipmentElementEditModule
} from "./shared/components/modals/modal-equipment-element-edit/modal-equipment-element-edit.module";
import {
  ModalEquipmentCoatingEditModule
} from "./shared/components/modals/modal-equipment-coating-edit/modal-equipment-coating-edit.module";
import {
  ModalEquipmentStyleEditModule
} from "./shared/components/modals/modal-equipment-style-edit/modal-equipment-style-edit.module";
import {NzModalModule} from "ng-zorro-antd/modal";
import {ModalConfirmableModule} from "./shared/components/modals/modal-confirmable/modal-confirmable.module";
import {CsrfInterceptor} from "./shared/interceptors/CsrfInterceptor";
import {ErrorInterceptor} from "./shared/interceptors/ErrorInterceptor";
import {NzDrawerModule} from "ng-zorro-antd/drawer";
import {NzMessageModule} from "ng-zorro-antd/message";

registerLocaleData(fr);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    IconModule,
    BrowserModule,
    NzModalModule,
    NzDrawerModule,
    NzMessageModule,
    AppRoutingModule,
    HttpClientModule,
    FullCalendarModule,
    NzNotificationModule,
    QuillModule.forRoot(),
    ModalConfirmableModule,
    BrowserAnimationsModule,

    ModalEquipmentStyleEditModule,
    ModalEquipmentCoatingEditModule,
    ModalEquipmentElementEditModule,
    ModalEquipmentCategoryEditModule,
    ModalEquipmentMaterialEditModule,

    environment.production ? [] : AkitaNgDevtools.forRoot()
  ],
  providers: [
    {
      provide: NG_ENTITY_SERVICE_CONFIG,
      useValue: {
        baseUrl: 'https://jsonplaceholder.typicode.com'
      }
    },
    {
      provide: NZ_I18N,
      useFactory: (localId: string) => {
        switch (localId) {
          case 'fr':
            return fr_FR;
          default:
            return fr_FR;
        }
      },
      deps: [LOCALE_ID]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CsrfInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    }
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
}
