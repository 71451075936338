import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FieldComponent} from './field.component';
import {ReactiveFormsModule} from "@angular/forms";
import {IconModule} from "../../icon/icon.module";
import {NzTypographyModule} from "ng-zorro-antd/typography";

@NgModule({
  declarations: [
    FieldComponent
  ],
  exports: [
    FieldComponent
  ],
    imports: [
        IconModule,
        CommonModule,
        ReactiveFormsModule,
        NzTypographyModule
    ]
})
export class FieldModule {
}
