export const apiOriginUrl: string = 'https://api.amitel.azureo.eu/';

export const environment = {
  production: false,
  local: '/web/assets/data',
  origin: `${apiOriginUrl}`,
  apiV1: '/api/v1/',
  service: {
    user: 'bff/service-user',
    core: 'bff/service-core',
  }
};
