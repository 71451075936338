import {Injectable} from "@angular/core";
import {Store, StoreConfig} from "@datorama/akita";
import {AppConfig} from "../../shared/models/store/AppConfig";

export type ConfigState = AppConfig;

function createInitialState(): ConfigState {
  return {
    languages: [],
    settingsEmail: [],
    settingsSandbox: [],
    organizations: [],
  };
}

@Injectable({
  providedIn: 'root'
})
@StoreConfig({
  name: 'project'
})
export class ConfigStore extends Store<ConfigState> {
  constructor() {
    super(createInitialState());
  }
}
