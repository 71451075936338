import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SvgIconsModule} from "@ngneat/svg-icon";
import {asBriefcaseOutlineIcon} from "./svg/outline-briefcase";
import {asCashOutlineIcon} from "./svg/outline-cash";
import {asCalendarOutlineIcon} from "./svg/outline-calendar";
import {asClockOutlineIcon} from "./svg/outline-clock";
import {asCogOutlineIcon} from "./svg/outline-cog";
import {asCurrencyEuroIcon} from "./svg/outline-currency-euro";
import {asCreditCardOutlineIcon} from "./svg/outline-credit-card";
import {asFolderOpenOutlineIcon} from "./svg/outline-folder-open";
import {asUserGroupOutlineIcon} from "./svg/outline-user-group";
import {asHeartOutlineIcon} from "./svg/outline-heart";
import {asHomeOutlineIcon} from "./svg/outline-home";
import {asInboxOutlineIcon} from "./svg/outline-inbox";
import {asReceiptRefundOutlineIcon} from "./svg/outline-receipt-refund";
import {asLibraryOutlineIcon} from "./svg/outline-library";
import {asExpandArrowIcon} from "./svg/solid-expand-arrow";
import {asSpinnerIcon} from "./svg/solid-spinner";
import {asArrowSmUpIcon} from "./svg/solid-arrow-sm-up";
import {asArrowSmDownIcon} from "./svg/solid-arrow-sm-down";
import {asRedirectionIcon} from "./svg/outline-redirection";
import {asCircleCheckOutlineIcon} from "./svg/outline-circle-check";
import {asExternalLinkOutlineIcon} from "./svg/outline-external-link";
import {asXCircleOutlineIcon} from "./svg/outline-x-circle";
import {asTrashOutlineIcon} from "./svg/outline-trash";
import {asBanOutlineIcon} from "./svg/outline-ban";
import {asLightningBoltOutlineIcon} from "./svg/outline-lightning-bolt";
import {asArchiveOutlineIcon} from "./svg/outline-archive";
import {asSearchIcon} from "./svg/solid-search";
import {asMailIcon} from "./svg/solid-mail";
import {asChevronRightIcon} from "./svg/solid-chevron-right";
import {asCalendarIcon} from "./svg/solid-calendar";
import {asLibraryIcon} from "./svg/solid-library";
import {asStarIcon} from "./svg/solid-star";
import {asXCircleIcon} from "./svg/solid-x-circle";
import {asChevronDownIcon} from "./svg/solid-chevron-down";
import {asVerticalDotsIcon} from "./svg/solid-vertical-dots";
import {asArrowDownRightIcon} from "./svg/solid-arrow-down-right";
import {asArrowUpRightIcon} from "./svg/solid-arrow-up-right";
import {asExclamationCircleIcon} from "./svg/solid-exclamation-circle";
import {asClipboardDocumentIcon} from "./svg/outline-clipboard-document";
import {asComputerDesktopIcon} from "./svg/outline-computer-desktop";
import {asInformationCircleMiniIcon} from "./svg/mini-information-circle";
import {asPaperClipMiniIcon} from "./svg/mini-paper-clip";
import {asFolderPlusXLOutlineIcon} from "./svg/outline-folder-plus-xl";
import {asExclamationTriangleMiniIcon} from "./svg/mini-exclamation-triangle";
import {asUserMiniIcon} from "./svg/mini-user";
import {asXMarkXSMiniIcon} from "./svg/mini-x-mark-xs";
import {asChevronUpSolidIcon} from "./svg/solid-chevron-up";
import {asChevronRightMiniIcon} from "./svg/mini-chevron-right";
import {asHomeMiniIcon} from "./svg/mini-home";
import {asXMarkOutlineIcon} from "./svg/outline-x-mark";
import {asSpinnerOutlineIcon} from "./svg/outline-spinner";
import {asCheckMiniIcon} from "./svg/mini-check";
import {asTagOutlineIcon} from "./svg/outline-tag";
import {asModernHouseOutlineIcon} from "./svg/outline-modern-house";
import {asChevronLeftMiniIcon} from "./svg/mini-chevron-left";
import {asChevronDoubleLeftMiniIcon} from "./svg/mini-chevron-double-left";
import {asChevronDoubleRightMiniIcon} from "./svg/mini-chevron-double-right";
import {asCalendarDaysMiniIcon} from "./svg/mini-calendar";
import {asEyeSlashOutlineIcon} from "./svg/outline-eye-slash";
import {asEyeOutlineIcon} from "./svg/outline-eye";
import {asPuzzlePieceOutlineIcon} from "./svg/outline-puzzle-piece";
import {asListBulletOutlineIcon} from "./svg/outline-list-bullet";
import {asBookOpenOutlineIcon} from "./svg/outline-book-open";
import {asChartBarOutlineIcon} from "./svg/outline-chart-bar";
import {asPresentationChartOutlineIcon} from "./svg/outline-presentation-chart";
import {asBars3CenterLeftOutlineIcon} from "./svg/outline-bars-3-center-left";
import {asMagnifyingOutlineIcon} from "./svg/outline-magnifying";
import {asBellOutlineIcon} from "./svg/outline-bell";
import {asMagnifyingGlassMiniIcon} from "./svg/mini-magnifying-glass";
import {asPlusCircleOutlineIcon} from "./svg/outline-plus-circle";
import {asBars4OutlineIcon} from "./svg/outline-bars-4";
import {asBarsArrowUpOutlineIcon} from "./svg/outline-bars-arrow-up";
import {asBarsArrowDownOutlineIcon} from "./svg/outline-bars-arrow-down";
import {asSquares2x2OutlineIcon} from "./svg/outline-squares2x2";
import {asArrowDownTrayOutlineIcon} from "./svg/outline-arrow-down-tray";
import {asFunnelOutlineIcon} from "./svg/outline-funnel";
import {asArrowPathOutlineIcon} from "./svg/outline-arrow-path";
import {asChevronUpMiniIcon} from "./svg/mini-chevron-up";
import {asChevronDownMiniIcon} from "./svg/mini-chevron-down";
import {asAffixMiniIcon} from "./svg/mini-affix";
import {asEllipsisHorizontalOutlineIcon} from "./svg/outline-ellipsis-horizontal";
import {asEllipsisVerticalOutlineIcon} from "./svg/outline-ellipsis-vertical";
import {asPaperAirplaneOutlineIcon} from "./svg/outline-paper-airplane";
import {asArrowPathMiniIcon} from "./svg/mini-arrow-path";
import {asKeyMiniIcon} from "./svg/mini-key";
import {asTrashMiniIcon} from "./svg/mini-trash";
import {asArrowDownTrayMiniIcon} from "./svg/mini-arrow-down-tray";
import {asShareMiniIcon} from "./svg/mini-share";
import {asBuildingLibraryOutlineIcon} from "./svg/outline-building-library";
import {asImageAddOutlineIcon} from "./svg/outline-image-add";
import {asLockClosedOutlineIcon} from "./svg/outline-lock-closed";
import {asMediaObjectOutlineIcon} from "./svg/outline-media-object";
import {asSunOutlineIcon} from "./svg/outline-sun";
import {asWrenchScrewdriverOutlineIcon} from "./svg/outline-wrench-screwdriver";
import {asDocumentCheckOutlineIcon} from "./svg/outline-document-check";
import {asBars3OutlineIcon} from "./svg/outline-bars-3";
import {asBars3BottomLeftOutlineIcon} from "./svg/outline-bars-3-bottom-left";
import {asSparklesOutlineIcon} from "./svg/outline-sparkles";
import {asCheckCircleSolidIcon} from "./svg/solid-check-circle";
import {asKeyOutlineIcon} from "./svg/outline-key";
import {asArrowRightOnRectangleOutlineIcon} from "./svg/outline-arrow-right-on-rectangle";
import {asUserOutlineIcon} from "./svg/outline-user";
import {asFolderOutlineIcon} from "./svg/outline-folder";
import {asFlagOutlineIcon} from "./svg/outline-flag";
import {asRectangleGroupIcon} from "./svg/outline-rectangle-group";
import {asActivityOutlineIcon} from "./svg/outline-activity";
import {asArrowRightOutlineIcon} from "./svg/outline-arrow-right";
import {asBedOutlineIcon} from "./svg/outline-bed";
import {asBathSolidIcon} from "./svg/solid-bath";
import {asBathOutlineIcon} from "./svg/outline-bath";
import {asCouchSolidIcon} from "./svg/solid-couch";
import {asCouchOutlineIcon} from "./svg/outline-couch";
import {asBedSolidIcon} from "./svg/solid-bed";
import {asKitchenSolidIcon} from "./svg/solid-kitchen";
import {asDeckOutlineIcon} from "./svg/outline-deck";
import {asDoorSlidingOutlineIcon} from "./svg/outline-door-sliding";
import {asDoorOutlineIcon} from "./svg/outline-door";
import {asArrowUpOutlineIcon} from "./svg/outline-arrow-up";
import {asArrowDownOutlineIcon} from "./svg/outline-arrow-down";
import {asArrowUpMiniIcon} from "./svg/mini-arrow-up";
import {asArrowDownMiniIcon} from "./svg/mini-arrow-down";
import {asPencilSquareOutlineIcon} from "./svg/outline-pencil-square";
import {asDocumentTextOutlineIcon} from "./svg/outline-document-text";
import {asArrowUpOnSquareOutlineIcon} from "./svg/outline-arrow-up-on-square";
import {asInboxArrowDownOutlineIcon} from "./svg/outline-inbox-arrow-down";
import {asCheckOutlineIcon} from "./svg/outline-check";
import {asArrowTopRightOnSquareOutlineIcon} from "./svg/outline-arrow-top-right-on-square";
import {asCleanOutlineIcon} from "./svg/outline-clean";
import {asQuestionCircleOutlineIcon} from "./svg/outline-question-circle";
import {asArrowLeftMiniIcon} from "./svg/mini-arrow-left";
import {asDocumentDuplicateOutlineIcon} from "./svg/outline-document-duplicate";
import {asKeySpinnerIcon} from "./svg/mini-spinner";
import {asMoonOutlineIcon} from "./svg/outline-moon";
import {asPaperClipOutlineIcon} from "./svg/outline-paper-clip";
import {asFolderMinusOutlineIcon} from "./svg/outline-folder-minus";
import {asSwatchOutlineIcon} from "./svg/outline-swatch";
import {asAmitelSolidIcon} from "./svg/solid-amitel";
import {asChatBubbleOvalLeftEllipsisBarOutlineIcon} from "./svg/outline-chat-bubble-oval-left-ellipsis";
import {asCheckBadgeSolidIcon} from "./svg/solid-check-badge";
import {asSolidPdfIcon} from "./svg/solid-pdf";
import {asSolidXlsxIcon} from "./svg/solid-xlsx";
import {asSolidJpgIcon} from "./svg/solid-jpg";
import {asArrowRightStartOnRectangleOutlineIcon} from "./svg/outline-arrow-right-start-on-rectangle";
import {asPlusSignSquareOutlineIcon} from "./svg/outline-plus-sign-square";
import {asHierarchySquareOutlineIcon} from "./svg/outline-hierarchy-square";
import {asMinusSignSquareOutlineIcon} from "./svg/outline-minus-sign-square";
import {asDatabaseSyncOutlineIcon} from "./svg/outline-database-sync";
import {asArrowRightMiniIcon} from "./svg/mini-arrow-right";
import {asPlusMiniIcon} from "./svg/mini-plus";
import {asMinusMiniIcon} from "./svg/mini-minus";
import {asDotMiniIcon} from "./svg/mini-dot";
import {asPlusOutlineIcon} from "./svg/outline-plus";
import {asXMarkMiniIcon} from "./svg/mini-x-mark";
import {asShareOutlineIcon} from "./svg/outline-share";
import {asPaintBoardOutlineIcon} from "./svg/outline-paint-board";
import {asCalendarDateRangeOutlineIcon} from "./svg/outline-calendar-date-range";
import {asEnvelopeOutlineIcon} from "./svg/outline-envolope";
import {asArrowRightEndOnRectangleOutlineIcon} from "./svg/outline-arrow-left-start-on-rectangle";
import {asQueueListOutlineIcon} from "./svg/outline-queue-list";


@NgModule({
  imports: [
    CommonModule,
    SvgIconsModule.forRoot({
      sizes: {
        xs: '16px',
        sm: '20px',
        md: '24px',
        lg: '28px',
        xl: '32px',
        xxl: '40px',
        xxxl: '64px',
        xxxxl: '128px',
      },
      defaultSize: 'md',
      icons: [
        asEnvelopeOutlineIcon,
        asCalendarDateRangeOutlineIcon,
        asPaintBoardOutlineIcon,
        asPlusOutlineIcon,
        asDotMiniIcon,
        asPlusMiniIcon,
        asMinusMiniIcon,
        asDatabaseSyncOutlineIcon,
        asPlusSignSquareOutlineIcon,
        asHierarchySquareOutlineIcon,
        asMinusSignSquareOutlineIcon,
        asSolidJpgIcon,
        asSolidXlsxIcon,
        asSolidPdfIcon,
        asCheckBadgeSolidIcon,
        asArrowRightEndOnRectangleOutlineIcon,
        asChatBubbleOvalLeftEllipsisBarOutlineIcon,
        asAmitelSolidIcon,
        asSwatchOutlineIcon,
        asPaperClipOutlineIcon,
        asMoonOutlineIcon,
        asKeySpinnerIcon,
        asDocumentDuplicateOutlineIcon,
        asArrowRightMiniIcon,
        asArrowLeftMiniIcon,
        asQuestionCircleOutlineIcon,
        asCleanOutlineIcon,
        asArrowTopRightOnSquareOutlineIcon,
        asCheckOutlineIcon,
        asInboxArrowDownOutlineIcon,
        asArrowUpOnSquareOutlineIcon,
        asDocumentTextOutlineIcon,
        asPencilSquareOutlineIcon,
        asArrowUpMiniIcon,
        asArrowDownMiniIcon,
        asArrowUpOutlineIcon,
        asArrowDownOutlineIcon,
        asDoorOutlineIcon,
        asDoorSlidingOutlineIcon,
        asQueueListOutlineIcon,
        asDeckOutlineIcon,
        asKitchenSolidIcon,
        asBedSolidIcon,
        asCouchSolidIcon,
        asCouchOutlineIcon,
        asBathSolidIcon,
        asBathOutlineIcon,
        asBedOutlineIcon,
        asArrowRightOutlineIcon,
        asActivityOutlineIcon,
        asRectangleGroupIcon,
        asFlagOutlineIcon,
        asFolderMinusOutlineIcon,
        asFolderOutlineIcon,
        asUserOutlineIcon,
        asArrowRightStartOnRectangleOutlineIcon,
        asArrowRightOnRectangleOutlineIcon,
        asKeyOutlineIcon,
        asCheckCircleSolidIcon,
        asShareOutlineIcon,
        asSparklesOutlineIcon,
        asBars3BottomLeftOutlineIcon,
        asBars3OutlineIcon,
        asDocumentCheckOutlineIcon,
        asWrenchScrewdriverOutlineIcon,
        asSunOutlineIcon,
        asMediaObjectOutlineIcon,
        asLockClosedOutlineIcon,
        asImageAddOutlineIcon,
        asBuildingLibraryOutlineIcon,
        asShareMiniIcon,
        asArrowDownTrayMiniIcon,
        asTrashMiniIcon,
        asKeyMiniIcon,
        asArrowPathMiniIcon,
        asPaperAirplaneOutlineIcon,
        asEllipsisHorizontalOutlineIcon,
        asEllipsisVerticalOutlineIcon,
        asAffixMiniIcon,
        asChevronDownMiniIcon,
        asChevronUpMiniIcon,
        asArrowPathOutlineIcon,
        asFunnelOutlineIcon,
        asArrowDownTrayOutlineIcon,
        asSquares2x2OutlineIcon,
        asBars4OutlineIcon,
        asBarsArrowUpOutlineIcon,
        asBarsArrowDownOutlineIcon,
        asPlusCircleOutlineIcon,
        asMagnifyingGlassMiniIcon,
        asBellOutlineIcon,
        asMagnifyingOutlineIcon,
        asBars3CenterLeftOutlineIcon,
        asPresentationChartOutlineIcon,
        asChartBarOutlineIcon,
        asFolderOpenOutlineIcon,
        asBookOpenOutlineIcon,
        asListBulletOutlineIcon,
        asPuzzlePieceOutlineIcon,
        asEyeOutlineIcon,
        asEyeSlashOutlineIcon,
        asCalendarDaysMiniIcon,
        asChevronDoubleLeftMiniIcon,
        asChevronDoubleRightMiniIcon,
        asChevronLeftMiniIcon,
        asTagOutlineIcon,
        asModernHouseOutlineIcon,
        asCheckMiniIcon,
        asSpinnerOutlineIcon,
        asXMarkOutlineIcon,
        asHomeMiniIcon,
        asChevronRightMiniIcon,
        asChevronUpSolidIcon,
        asXMarkMiniIcon,
        asXMarkXSMiniIcon,
        asUserMiniIcon,
        asExclamationTriangleMiniIcon,
        asFolderPlusXLOutlineIcon,
        asPaperClipMiniIcon,
        asInformationCircleMiniIcon,
        asComputerDesktopIcon,
        asClipboardDocumentIcon,
        asExclamationCircleIcon,
        asArrowUpRightIcon,
        asArrowDownRightIcon,
        asVerticalDotsIcon,
        asChevronDownIcon,
        asXCircleIcon,
        asStarIcon,
        asLibraryIcon,
        asCalendarIcon,
        asChevronRightIcon,
        asMailIcon,
        asSearchIcon,
        asArchiveOutlineIcon,
        asLightningBoltOutlineIcon,
        asBanOutlineIcon,
        asTrashOutlineIcon,
        asXCircleOutlineIcon,
        asExternalLinkOutlineIcon,
        asCircleCheckOutlineIcon,
        asRedirectionIcon,
        asArrowSmUpIcon,
        asArrowSmDownIcon,
        asSpinnerIcon,
        asExpandArrowIcon,
        asLibraryOutlineIcon,
        asBriefcaseOutlineIcon,
        asCashOutlineIcon,
        asCalendarOutlineIcon,
        asClockOutlineIcon,
        asCogOutlineIcon,
        asCurrencyEuroIcon,
        asCreditCardOutlineIcon,
        asFolderOpenOutlineIcon,
        asUserGroupOutlineIcon,
        asHeartOutlineIcon,
        asHomeOutlineIcon,
        asInboxOutlineIcon,
        asReceiptRefundOutlineIcon
      ]
    })
  ],
  exports: [
    SvgIconsModule
  ]
})
export class IconModule {
}
