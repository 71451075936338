import {
  differenceInCalendarYears,
  addBusinessDays,
  subYears,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  addMonths,
  startOfYear,
  endOfYear,
  sub,
  subDays,
  isPast,
  isFuture,
  subMonths,
  differenceInBusinessDays,
  differenceInDays,
  differenceInHours,
  isToday,
  addDays,
  eachDayOfInterval,
  startOfDay,
  endOfDay
} from "date-fns";
import format from "date-fns/format";

export class DateUtil {

  /**
   *
   *
   * FORMATTER
   */

  public static formatDate(date: number): string {
    return format(date, "dd/MM/yyyy HH:mm");
  }

  public static formatDateWithoutTime(date: number | Date): string {
    return format(date, "dd/MM/yyyy");
  }

  /**
   *
   *
   * DAYS
   */

  public static addBusinessDays(date: number | Date, days: number): Date {
    return addBusinessDays(date, days);
  }

  public static addDays(date: number | Date, days: number): Date {
    return addDays(date, days);
  }

  public static today(): number {
    return new Date().getTime();
  }

  public static startOfToday(): number {
    return startOfDay(new Date()).getTime();
  }

  public static endOfToday(): number {
    return endOfDay(new Date()).getTime();
  }

  static startOfDay(date: number | Date): Date {
    return startOfDay(date);
  }

  public static startOfYesterday(): number {
    return startOfDay(subDays(this.today(), 1)).getTime();
  }

  public static endOfYesterday(): number {
    return endOfDay(subDays(this.today(), 1)).getTime();
  }

  public static tomorrow(): number {
    return addDays(this.today(), 1).getTime();
  }

  public static in2days(): number {
    return addBusinessDays(this.today(), +2).getTime();
  }

  public static in3days(): number {
    return addBusinessDays(this.today(), +3).getTime();
  }

  /**
   *
   *
   * WEEKS
   */

  public static currentWeek(): number[] {
    return [
      startOfWeek(this.today(), { weekStartsOn: 1 }).getTime(),
      endOfWeek(this.today(), { weekStartsOn: 1 }).getTime()
    ];
  }

  public static in1Week(): number {
    return addDays(this.today(), +7).getTime();
  }

  public static in2Weeks(): number {
    return addDays(this.today(), +14).getTime();
  }


  /**
   *
   *
   * MONTHS
   */

  public static addMonths(date: number | Date, amount: number): Date {
    return addMonths(date, amount);
  }

  public static subMonths(date: number | Date, amount: number): Date {
    return subMonths(date, amount);
  }

  public static currentMonth(): number[] {
    return [
      startOfMonth(this.today()).getTime(),
      endOfMonth(this.today()).getTime()
    ];
  }

  public static lastMonth(): number[] {
    const date: Date = subMonths(this.today(), 1);
    return [
      startOfMonth(date).getTime(),
      endOfMonth(date).getTime()
    ];
  }

  public static last2Months(): number[] {
    const date: Date = subMonths(this.today(), 2);
    return [
      startOfMonth(date).getTime(),
      endOfMonth(this.today()).getTime()
    ];
  }

  public static last3Months(): number[] {
    const date: Date = subMonths(this.today(), 3);
    return [
      startOfMonth(date).getTime(),
      endOfMonth(this.today()).getTime()
    ];
  }

  public static in1Month(): number {
    return addMonths(this.today(), +1).getTime();
  }

  public static in3Month(): number {
    return addMonths(this.today(), +3).getTime();
  }

  public static in6Month(): number {
    return addMonths(this.today(), +6).getTime();
  }


  /**
   *
   *
   * YEARS
   */

  public static subYears(date: number | Date, amount: number): Date {
    return sub(date, { years: amount });
  }

  public static startOfYear(date: number | Date): Date {
    return startOfYear(date);
  }

  public static endOfYear(date: number | Date): Date {
    return endOfYear(date);
  }

  public static currentYear(): number[] {
    const date: number = this.today();
    return [
      startOfYear(date).getTime(),
      endOfYear(date).getTime()
    ];
  }

  public static lastYear(): number[] {
    const date: Date = subYears(this.today(), 1);
    return [
      startOfYear(date).getTime(),
      endOfYear(date).getTime()
    ];
  }

  /**
   *
   *
   * DELTA / DIFFERENCES
   */

  public static differenceInYears(leftDate: number, rightDate: number): number {
    return differenceInCalendarYears(leftDate, rightDate);
  }

  public static differenceInHours(leftDate: number, rightDate: number): number {
    return differenceInHours(rightDate, leftDate);
  }

  public static differenceInDays(leftDate: number, rightDate: number): number {
    return differenceInDays(rightDate, leftDate);
  }

  public static differenceInBusinessDays(leftDate: number, rightDate: number): number {
    return differenceInBusinessDays(rightDate, leftDate);
  }









  public static isToday(date: number | Date): boolean {
    return isToday(date);
  }

  static isPast(date: number | Date) {
    return isPast(date);
  }

  static isFuture(date: number | Date) {
    return isFuture(date);
  }

  static eachDayOfInterval(leftDate: number, rightDate: number) {
    return eachDayOfInterval({ start: startOfDay(leftDate), end: startOfDay(rightDate) });
  }
}
