import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: async () =>
      (await import('./modules/auth/auth.module')).AuthModule
  },
  {
    path: 'services',
    loadChildren: async () =>
      (await import('./modules/booking/project.module')).ProjectModule
  },
  {
    path: 'requests',
    loadChildren: async () =>
      (await import('./modules/request/request.module')).RequestModule
  },
  {
    path: 'admin',
    loadChildren: async () =>
      (await import('./modules/admin/admin.module')).AdminModule
  },
  {
    path: 'profile',
    loadChildren: async () =>
      (await import('./modules/profile/profile.module')).ProfileModule
  },
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
