import {Injectable} from "@angular/core";

export const APP_PREFIX: string = 'BO-';
export const KEY_THEME: string = 'KEY_THEME';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
  constructor() { }

  public setItem(key: string, value: any): void {
    try {
      localStorage.setItem(`${APP_PREFIX}${key}`, JSON.stringify(value));
    } catch (e) {
      console.error(e)
    }
  }

  public getItem(key: string): any {
    const value: string | null = localStorage.getItem(`${APP_PREFIX}${key}`);
    try {
      return JSON.parse(value as any);
    } catch (e) {
      return value;
    }
  }

  public deleteItem(key: string): void {
    localStorage.removeItem(`${APP_PREFIX}-${key}`);
  }
}
