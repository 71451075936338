import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {enableAkitaProdMode} from "@datorama/akita";
import {environment} from "../environments/environment";
import {ProjectService} from "./store/base/project.service";
import {ConfigService} from "./store/config/config.service";


@Component({
  selector: 'as-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  constructor(
    private configService: ConfigService,
    private projectService: ProjectService,
  ) {
    this.configService.initConfig();
    if (environment.production) {
      enableAkitaProdMode();
    }
  }
  ngOnInit(): void {
    this.projectService.init();
  }

}
