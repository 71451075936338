export const asAmitelSolidIcon = {
  data: `<svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_6_7)">
<path d="M5 0.706415V3.22993L9.58535 2.76658V7L12 6.76284V0L5 0.706415Z" fill="currentColor"/>
</g>
<g clip-path="url(#clip1_6_7)">
<path d="M7 13.2936L7 10.7701L2.41465 11.2334L2.41466 7L5.91226e-07 7.23716L0 14L7 13.2936Z" fill="currentColor"/>
</g>
<defs>
<clipPath id="clip0_6_7">
<rect width="7" height="7" fill="white" transform="translate(5)"/>
</clipPath>
<clipPath id="clip1_6_7">
<rect width="7" height="7" fill="white" transform="translate(7 14) rotate(-180)"/>
</clipPath>
</defs>
</svg>`,
  name: 'solid/amitel'
};
