import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ModalEquipmentElementEditComponent} from './modal-equipment-element-edit.component';
import {FieldModule} from "../../inputs/field/field.module";
import {ButtonModule} from "../../buttons/button/button.module";
import {ReactiveFormsModule} from "@angular/forms";


@NgModule({
  declarations: [
    ModalEquipmentElementEditComponent
  ],
  exports: [
    ModalEquipmentElementEditComponent
  ],
  imports: [
    FieldModule,
    CommonModule,
    ButtonModule,
    ReactiveFormsModule
  ]
})
export class ModalEquipmentElementEditModule {
}
