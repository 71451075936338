import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ModalEquipmentMaterialEditComponent} from './modal-equipment-material-edit.component';
import {FieldModule} from "../../inputs/field/field.module";
import {ButtonModule} from "../../buttons/button/button.module";
import {ReactiveFormsModule} from "@angular/forms";


@NgModule({
  declarations: [
    ModalEquipmentMaterialEditComponent
  ],
  exports: [
    ModalEquipmentMaterialEditComponent
  ],
  imports: [
    FieldModule,
    CommonModule,
    ButtonModule,
    ReactiveFormsModule
  ]
})
export class ModalEquipmentMaterialEditModule {
}
